import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"

const Page = ({ pageContext }) => {
  const {
    seoData,
    scriptId,
    homeBreadcrumbTitle,
    containerClass,
    headerImages,
  } = pageContext

  const isProdEnv = process.env.GATSBY_ACTIVE_ENV === "production"

  const oneTrustId = scriptId?.replace("otnotice-", "")
  const endpoint = isProdEnv
    ? `https://privacyportalde-cdn.onetrust.com/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/privacy-notices/${oneTrustId}.json`
    : `https://privacyportalde-cdn.onetrust.com/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/privacy-notices/draft/${oneTrustId}.json`

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== "undefined" && window.OneTrust) {
        OneTrust.NoticeApi.Initialized.then(function () {
          OneTrust.NoticeApi.LoadNotices([endpoint], isProdEnv)
        })
      }
    }, 150)
  }, [])

  return (
    <Layout
      seoData={seoData}
      headerImages={headerImages}
      homeBreadcrumbTitle={homeBreadcrumbTitle}
    >
      {/* Container in which the privacy notice will be rendered  */}
      <div id={scriptId} className={`otnotice ${containerClass}`}></div>
    </Layout>
  )
}
export const Head = () => {
  return (
    <script
      src="https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
      type="text/javascript"
      id="otprivacy-notice-script"
    >
      {/* settings=eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1kZS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9 */}
    </script>
  )
}

Page.propTypes = {
  pageContext: PropTypes.shape({
    componentProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    breadCrumbs: PropTypes.object,
    breadcrumbTitle: PropTypes.string,
    homeBreadcrumbTitle: PropTypes.string,
    scriptId: PropTypes.string,
    containerClass: PropTypes.string,
    headerImages: PropTypes.object,
    seoData: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      ogTitle: PropTypes.string,
      ogDescription: PropTypes.string,
      ogType: PropTypes.string,
      twitterTitle: PropTypes.string,
      twitterDescription: PropTypes.string,
      canonicalLink: PropTypes.string,
      metaTitle: PropTypes.string,
      breadcrumbTitle: PropTypes.string,
      featuredImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
}

export default Page
